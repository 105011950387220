<template>
  <div class="Top2  container-fluid page" style="min-height: 100vh; text-align: left">
    <!-- <div style="height: 200px" /> -->
    <div class="mainArea" style="margin:auto;"> 
    <div class="main">
      <div class="oomidashi bgTest" style="font-size:40px">プライバシーポリシー</div>

      <div class="sp_line_m" />

      <div class="common-sentence">
        osaiten.com（以下「当方」）は、本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
      </div>

      <div class="sp_line_l" />
      <p class="oomidashi">基本方針</p>
      <div class="sp_line_ss" />
      <div class="common-sentence">
        当方は、個人情報保護法、個人情報保護に関するガイドライン等の指針その他個人情報保護に関する関係法令を遵守します。
      </div>
      
      <div class="sp_line_l" />
      <p class="oomidashi">定義</p>
      <div class="sp_line_ss" />
      <div class="common-sentence">
        本ポリシーにおいて、「個人情報保護法」とは、個人情報の保護に関する法律を意味します。<br>
        また、本ポリシーにおいて、「個人情報」とは、個人情報保護法第2条第1項に定義される個人情報を意味します。
      </div>
      
      <div class="sp_line_l" />
      <p class="oomidashi">個人情報の利用目的</p>
      <div class="sp_line_ss" />
      <div class="common-sentence">
        当方が本サービスに関して取得した個人情報の利用目的は、次のとおりです。
        <ul>
          <li>本サービスの提供・管理・運営及びその連絡</li>
          <li>ユーザーが他のユーザーその他第三者への公開対象として指定した投稿等の情報に含まれる個人情報の本サービスを通じた他のユーザーその他第三者への公開</li>
          <li>当方との間における本サービスの提供を含む契約の締結・履行及び契約後の管理</li>
          <li>本サービスに関する料金の請求収納・債権保全</li>
          <li>当方への資料請求・お問い合わせ・ご相談・修理・サポートへの対応及びこれらに関する確認・記録</li>
          <li>当方との契約、当方の規約・ポリシー等に違反する行為への対応</li>
          <li>懸賞、キャンペーン等の実施</li>
          <li>商品開発その他当方の商品・サービスの改善・向上</li>
          <li>市場調査、マーケティング情報の分析その他の調査・分析・研究</li>
          <li>当方のサービスの運営を目的とする個人情報が特定されない統計データの収集・作成・送付</li>
          <li>当方の商品・サービスに関する重要なお知らせ、社会的慣習に基づくご連絡・ご挨拶等、必要に応じた連絡の実施</li>
          <li>当方及び第三者の商品・サービス、セミナー、講演会等の広告・宣伝</li>
          <li>施設・設備・機器の保守管理及びその利用状況の管理</li>
          <li>前各号の利用目的に付随する利用目的</li>
        </ul>
      </div>
      
      <div class="sp_line_l" />
      <p class="oomidashi">個人情報の第三者への提供</p>
      <div class="sp_line_ss" />
      <div class="common-sentence">
        当方は、次のいずれかに該当する場合を除き、個人情報を第三者に提供することはありません。
        <ul>
          <li>ご本人様の同意がある場合</li>
          <li>当方が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合</li>
          <li>前各号の場合のほか、個人情報保護法その他の法令で認められる場合</li>
        </ul>
      </div>
      
      <div class="sp_line_l" />
      <p class="oomidashi">個人情報の取扱いの委託</p>
      <div class="sp_line_ss" />
      <div class="common-sentence">
        当方は、個人情報の取扱いを第三者に委託する場合、信頼できる協力会社等に委託し、機密保持についても適切な管理を行います。
      </div>

      <div class="sp_line_l" />
      <p class="oomidashi">個人情報の開示・訂正・利用停止等</p>
      <div class="sp_line_ss" />
      <div class="common-sentence">
        個人情報について、開示、訂正、利用停止等のお申し出があった場合には、ご本人様の申し出であることを確認の上、当方所定の方法に基づき対応します。具体的な方法は、個別にご案内しますので、本サービスのウェブサイト上のコンタクトフォームまでお問い合わせください。
      </div>

      <div class="sp_line_l" />
      <p class="oomidashi">Googleアナリティクス・Cookie等の利用</p>
      <div class="sp_line_ss" />
      <div class="common-sentence">
        当方ウェブサイトでは、当方ウェブサイトの利用状況の分析のためGoogleアナリティクスを利用していることから、当方ウェブサイトを利用するユーザーの端末にクッキー（Cookie）を保存し、これを利用して利用者情報を蓄積及び利用している場合があります。
        Googleアナリティクスの詳細は次のとおりです。
        <ul>
          <li>提供会社：Google LLC</li>
          <li>取得項目：https://policies.google.com/privacy?hl=ja</li>
          <li>提供会社のプライバシーポリシー等：https://policies.google.com/privacy?hl=ja</li>
          <li>Googleアナリティクスでデータが収集・処理される仕組み：https://www.google.com/intl/ja/policies/privacy/partners/</li>
          <li>オプトアウトの方法：https://tools.google.com/dlpage/gaoptout?hl=ja</li>
        </ul>
      </div>
      <div class="common-sentence">
        当方は、当方ウェブサイトにおける情報の収集に、クッキー（Cookie）を含むユーザーの識別情報、ウェブサイト内の行動履歴情報並びにご使用のデバイス及びアプリケーションソフトに関する情報と接続情報を取得し、利用します。
        クッキー（Cookie）とは、ウェブサイトを利用するユーザーの情報を、コンピュータ（記憶装置）及びアプリケーションソフト上で記録管理する技術のことをいいます。
      </div>
      <div class="common-sentence">
        Googleなどの第三者配信事業者は、クッキー（Cookie）を使用して、ユーザーが本ウェブサイトや他のウェブサイトに過去にアクセスした際の情報に基づいて広告を配信することがあります。
      </div>
      <div class="common-sentence">
        ブラウザーでクッキー（Cookie）の使用設定を変更してクッキー（Cookie）使用を拒否された場合には、当方ウェブサイトにおけるサービスの一部又は全部がご利用頂けないことがあります。
      </div>

      <div class="sp_line_l" />
      <p class="oomidashi">プライバシーポリシーの変更・改定</p>
      <div class="sp_line_ss" />
      <div class="common-sentence">
        当方は、本プライバシーポリシーを予告なく変更又は改定する場合があります。
      </div>
      <div class="sp_line_m" />
      【2024年5月15日制定】
    </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
  },
  components: {
  },
  data() {
    return {
    }
  },
  created : function () {
  },
  computed : {
  },
  methods : {
    initAction : function () {
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.initAction();
    });
  },
  beforeRouteUpdate(to, from, next) {
    next(vm => {
      vm.initAction();
    });
  },
  watch : {
  }
}
</script>

<style>
 .common-sentence {
    text-indent: 1em;
 }
</style>