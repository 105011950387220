<template>
  <div class="Top2  container-fluid page" style="min-height: 100vh; text-align: left">
    <!-- <div style="height: 200px" /> -->
    <div class="mainArea" style="margin:auto;"> 
    <div class="main">
      <div class="oomidashi bgTest" style="font-size:40px">利用規約</div>

      <div class="sp_line_m" />

      <div class="common-sentence">
        本規約は、『おサイてん』（以下 本サイト）の利用条件、利用に関する諸事項、その他必要な事項を定めたものです。本サイトをご利用される方には、以下の利用規約に同意の下、本サイトのサービスをご利用頂きます。また、本サービスを利用・閲覧することにより本規約の内容を承諾頂いたものとみなします。
      </div>
      <div class="sp_line_l" />

      <p class="oomidashi">第1条 本サイトの利用</p>
      <div class="sp_line_ss" />
      <div class="common-sentence">
        投稿に必要な情報は、利用者が所有する端末にて入力し、利用者の責任の下、本サイトに送信して頂きます。万が一、第三者が所有する端末を使用した場合や、他人の氏名やそれに準ずるものを使用したと本サイトが判断した場合には、本サイトは当該利用者の投稿を削除し、将来にわたってサービスを利用することをお断りする権利を有します。
      </div>
      <div class="sp_line_l" />
      
      <p class="oomidashi">第2条 利用者の義務・責任</p>
      <div class="sp_line_ss" />
      <div>
        <ul>
          <li>
            利用者は、自らの責任のみにおいて利用し、本サイトを利用して得た情報を自らの判断と責任で使用するものとします。
          </li>
          <li>
            利用者は、本サイトにおける自己の行為について、本利用規約に対する違反等によって生じる一切の責任を負う物とします。
          </li>
          <li>
            利用者は、本利用規約に対する違反を発見した場合には、直ちに本サイトに報告するものとします。
          </li>
          <li>
            利用者は、本利用規約に違反し、本サイトに費用負担または損害を与えた場合には、当該違反等から発生する損害の賠償および弁護士費用を含む一切の費用を負担するものとします。
          </li>
          <li>
            利用者は、本サイトにおける自己の行為について、利用者自身が一切の法的な責任を負うものとします。本サイトは、本サイトにおける利用者の行為について、一切の責任を免除されるものとします。
          </li>
        </ul>
      </div>
      <div class="sp_line_l" />
      
      <p class="oomidashi">第3条 ユーザーコンテンツ</p>
      <div class="sp_line_ss" />
      <div>
        <ul>
          <li>
            ユーザーコンテンツの著作権はその著作物を作成した方に帰属します。
          </li>
          <li>
            本サイトは、利用者が本サイト内で投稿を行ったコンテンツを事前に検閲することは致しておりません。したがって誹謗中傷、差別、批判、猥褻な内容等の各利用者にとって不快な内容の投稿や日本国の法律に反する内容、もしくはそのおそれのある内容がユーザーコンテンツとして本サイト内に掲載される可能性もありますが、本サイトは、基本的にユーザーコンテンツの内容に関して一切関知しないものとし、一切の責任を負うものではありません。
          </li>
          <li>
            利用者は、ユーザーコンテンツに含まれる著作物について、当該著作物の著作権を自らが保有していること、自らが著作権者より正当に当該著作物の利用を許諾されていること、または適法に当該著作物を利用していることを保証するものとします。万一、第三者より利用者コンテンツに関して異議が申し立てられた場合、利用者自らの責任と費用負担によりこれを解決するものとします。
          </li>
          <li>
            利用者は、過失の有無に関わらず、自らのユーザーコンテンツおよびそのデータに起因する、物理的・精神的被害を含む一切の責任を持つものとします。
          </li>
          <li>
            本サイトは、本サイト内の全てのユーザーコンテンツを運営上の必要に応じて閲覧することができ、本利用規約に抵触すると自らの裁量に基づき判断した場合には、利用者への事前の通知なしに、当該ユーザーコンテンツの全部または一部を非公開設定または削除をすることができるものとします。
          </li>
          <li>
            本サイトは、利用者が本サイト上に掲載させるために送信した内容を掲載前に確認し、本利用規約に反すると判断した場合には、掲載制限・削除を行うことができるものとします。
          </li>
          <li>
            ユーザーコンテンツの削除依頼をされた場合において、削除を約束するものではありません。投稿内容によって利用者が損害をうけた場合であっても本サイトは何ら責任を負いません。
          </li>
          <li>
            弊社の権利・財産やサービスなどの保護、または第三者の生命、身体または財産の保護の必要があると本サイトが判断した場合には、必要な範囲内でユーザーコンテンツを警察等第三者機関に開示・提供することができるものとします。
          </li>
        </ul>
      </div>
      <div class="sp_line_l" />
      
      <p class="oomidashi">第4条 禁止事項</p>
      <div class="sp_line_ss" />
      <div>
        <ul>
          <li>本サイトが異性交際を希望するものと判断できる一切の行為</li>
          <li>売春や児童買春の勧誘・斡旋・またはそれに類似する一切の行為</li>
          <li>メールアドレス、電話番号等の個人情報を記載する行為</li>
          <li>
            性行為画像やヌード画像等の性的な情報・画像・映像・音声等をメール送信、掲示、リンク設定する行為
          </li>
          <li>第三者の本サイト利用を妨害する行為</li>
          <li>
            第三者に嫌悪感や迷惑を与える行為、差別、誹謗中傷、また第三者への不当な差別を助長するなど第三者を不愉快にさせる行為
          </li>
          <li>第三者の財産、プライバシーまたは信用を侵害する行為</li>
          <li>第三者の個人情報を無断で収集、開示する行為</li>
          <li>他の利用者・第三者になりすます行為</li>
          <li>
            権利者の承諾なく画像や歌詞等を掲載・メール送信等して著作権を侵害する行為
          </li>
          <li>
            第三者の肖像が写る画像・映像等を本人の許諾なく掲載・メール送信等して肖像権を侵害する行為
          </li>
          <li>
            本サイト又は第三者の知的財産権（特許権、意匠権、商標権等）を侵害する行為
          </li>
          <li>
            集団自殺や自殺予告・自殺方法等、死を助長・誘引させる内容を掲載・メール送信等をする行為
          </li>
          <li>
            本サイトを本サイトの事前の許可なく勧誘、営業、宣伝活動、譲渡、売買、交換等の取引の場として利用する行為
          </li>
          <li>本サイトのサーバーへの不正アクセスや過度な負荷をかける行為</li>
          <li>
            本サイトにおいて使用されているソフトウェアおよびデータの全部若しくは一部を複製、複写、修正、追加、改変および二次利用する行為
          </li>
          <li>
            本サイトの運営を妨害する行為または当該行為を誘引・助長させる行為
          </li>
          <li>法令または公序良俗に違反する行為</li>
          <li>犯罪行為に関連する行為</li>
          <li>その他本サイトが不適切と判断した全ての行為</li>
        </ul>
      </div>
      <div class="sp_line_l" />
      
      <p class="oomidashi">第5条 本サイトの利用制限</p>
      <div class="sp_line_ss" />
      <div>
        <ul>
          <li>
            本規約に違反するユーザー、または本サービスにおいて不適当であると運営者が判断したユーザーについて、運営者は当該ユーザーのユーザーコンテンツの削除、アカウントの制限・削除、サービスの利用の制限・禁止等の措置を行うことができます。
          </li>
          <li>
            運営者は、ユーザーへの事前の告知なしに次の措置をとることができます。また、次の措置を行ったユーザーのユーザー情報やユーザーコンテンツの一切の情報を、措置後引き続き保持する義務はないものとします。<br>
            <ol>
              <li>本サービスの全部または一部の提供中止</li>
              <li>ユーザーコンテンツの全部または一部の非公開設定または削除</li>
              <li>ユーザーアカウントの非表示設定または削除</li>
              <li>永久的なアクセス禁止処置</li>
            </ol>
          </li>
        </ul>
      </div>
      <div class="sp_line_l" />
      
      <p class="oomidashi">第6条 外部リンク</p>
      <div class="sp_line_ss" />
      <div>
        <ul>
          <li>
            本サイト内に掲載された広告、並びにリンク先のサービスの利用などについては、利用者と当該広告主リンク先サイトの責任において行って頂くこととし、本サイトは一切関知致しません。
          </li>
          <li>
            本サイトは、本サービス中に掲載されている広告・外部リンク先によって行われる取り引きサービスの利用による損害および広告が掲載されたこと自体に起因する損害について一切責任を負いません。
          </li>
        </ul>
      </div>
      <div class="sp_line_l" />

      <p class="oomidashi">第7条 個人情報について</p>
      <div class="sp_line_ss" />
      <div class="common-sentence">
        本サービスでお預かりした情報については、個人情報保護法に従い厳格に取扱うものとし、以下の目的の範囲内で利用致します。
        <ul>
          <li>
            個人を識別することができない状態での統計資料、本サービスの向上の目的で、情報を集計および分析などを行う為
          </li>
          <li>
            本サービスに係る電子メール・メールマガジン・各種お知らせを送信させて頂く為
          </li>
          <li>その他、上記目的に付帯する目的の為</li>
        </ul>
      </div>
      <div class="sp_line_l" />
      
      <p class="oomidashi">第8条 情報の提供</p>
      <div class="sp_line_ss" />
      <div class="common-sentence">
        本サービスは、利用者の情報・及び投稿内容について、情報開示を求められた場合・または必要に応じて以下の第三者に情報開示することがあります。
        <ul>
          <li>
            裁判所、警察、その他の司法もしくは行政機関、またはこれらに準ずる者から適法に照会があった場合
          </li>
          <li>
            第三者の権利を侵害している投稿内容に対するクレームに対応する為に必要な場合
          </li>
          <li>
            法令または本サイトが定める規約に違反し、その他社会通念上問題があると判断された場合
          </li>
          <li>
            本サイト、他の利用者、またはその他の第三者の権利、または利益を保護するため必要な場合
          </li>
          <li>その他本サイトが必要であると判断した場合</li>
        </ul>
      </div>
      <div class="sp_line_l" />

      <p class="oomidashi">第9条 免責</p>
      <div class="sp_line_ss" />
      <div>
        <ul>
          <li>
            本サイトは、基本的に利用者同士の通信や活動に一切関与しません。万一利用者同士の衝突トラブルや、サイト内に掲載された広告の広告主、リンク先のサイトとの間で問題が発生した場合も、当事者同士で解決するものとし、本サイトではその責任を一切負わないものとします。
          </li>
          <li>
            利用者が本サイトを利用するにあたり、本サイトの過失に基づく債務不履行または不法行為に起因して利用者に損害が生じた場合であっても、本サイトは損害を賠償する一切の責任を負わないものとします。
          </li>
          <li>
            本サイトは、利用者に発生した使用機会の逸失、業務の中断、期待した利益が得られなかったこと、および前項に記載する以外のあらゆる種類の損害に対して、いかなる責任も負わないものとします。
          </li>
        </ul>
      </div>
      <div class="sp_line_l" />
      
      <p class="oomidashi">第10条 サービスの変更</p>
      <div class="sp_line_ss" />
      <div class="common-sentence">
        本サイトの提供するサービスの中断、停止、変更、廃止についてはいつでも予告なく行うことが行えるものとし、利用者に対して何ら義務を負うものではありません。また本サイトのサービスの中断、停止、変更、廃止によって利用者に何かしらの損害が発生したとしても何ら責任を負うものではありません。本サイトでは、サービスの停止等を避ける為の処置として必要に応じて利用者のサービスの利用に関する制約をする場合があり、その権利を保有しています。
      </div>
      <div class="sp_line_l" />

      <p class="oomidashi">第11条 規約の変更</p>
      <div class="common-sentence">
        必要に応じて本規約を予告なしに変更することがありますが、改訂の都度の告知は致しかねますので、ご利用の際には最新の利用規約をご参照ください。本規約が変更された場合、変更した内容は本サイトへの掲示後、即時効力を発するものとします。
      </div>
      <div class="sp_line_m" />
      【2024年5月12日制定】
    </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},
  data() {
    return {};
  },
  created: function () {},
  computed: {},
  methods: {
    initAction: function () {
      this.$root.pageAction("TERMS");
      this.$root.closePopup();
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$root.showLoad();
      vm.initAction();
    });
  },
  beforeRouteUpdate(to, from, next) {
    next((vm) => {
      vm.$root.showLoad();
      vm.initAction();
    });
  },
  watch: {},
};
</script>

<style>
</style>