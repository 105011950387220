<template>
<div :class="theme_base" style="height:100%">
  <div class="headerBase">
    <div style="height:40px;" class="row  justify-content-between">
      <div class="col-6" style="margin-top:3px; display: flex">
        <img class="cursor" :src="this.$root.IMG_PATH + 'saiten_logo.png'" style="height:34px; margin-left:15px;" v-on:click="pushHome()">
      </div>
      <div class="col-6" style="margin-top:5px; text-align:right">
        <!-- <button type="button" class="btnBase btnBaseSp btnBaseH" style="width:150px;" v-on:click="pushStart">サイてんを始める！</button> -->
        <span style="margin-right:5px">
          <a href="https://twitter.com/osaiten_x?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-size="large" data-show-screen-name="false" data-lang="ja" data-show-count="false">Follow @osaiten_x</a>
        </span>
      </div>
      <!-- <div class="col-12" style="margin-top:5px; text-align:center">

      </div> -->
    </div>
    <div class="bar" />
  </div>
  <div class="constents" style="height:100%">
    <router-view ref="abc"></router-view>

    <div class="sp_line_l" />
    <div class="sp_line_l" />

    <div class="bar" />
    <div style="background-color:white">
      <span class="pointer fcMain1" v-on:click="pushCatBtn('TWITTER')">公式X（旧twitter）</span> &nbsp;/&nbsp;
      <span class="pointer fcMain1" v-on:click="pushCatBtn('INSTAGRAM')">公式Instagram</span> &nbsp;/&nbsp;
      <span class="pointer fcMain1" v-on:click="pushCatBtn('TERMS')">利用規約</span> &nbsp;/&nbsp;
      <span class="pointer fcMain1" v-on:click="pushCatBtn('PRIVACY')">プライバシーポリシー</span> &nbsp;/&nbsp;
      <span class="pointer fcMain1" v-on:click="pushCatBtn('ABOUT')">お問い合わせ</span>
      <br>
      ©2024 osaiten.com
    </div>


  </div>
</div>
<div v-show="isConnection" id="overlay"></div>
</template>

<script>
import axios from 'axios'
  
  //残タスク
  //コメントのNG処理
  //管理画面の処理
  //UI
  export default {
    data() {
      return {
        API_SUCCESS : 200,
        API_FAILED_LOGIN : 99,
        API_FAILED_AUTH : 100,
        API_FAILED_PARAM : 300,
        API_FAILED_NODATA : 400,
        API_FAILED_DUPLICATE : 401,
        API_FAILED_MISMATCH : 402,
        API_FAILED_PRIVATE : 500,
        API_FAILED_FILE : 600,
        API_FAILED_EXEPTION : 900,

        isLogin: false,
        userId : "",
        displayName : "",
        twitterId : "",

        IMG_PATH : "/app/img/saiten/",
        ICON_PATH : "/storage/icon/",
        CARD_PATH : "/storage/card/",
        
        URL_TWITTER : "https://twitter.com/osaiten_x",
        URL_INSTAGRAM : "https://www.instagram.com/osaiten_official/",

        // BASE_URL : "http://localhost:8001/",
        // SERVER_PATH : "http://localhost:8001/api/",
        // ROOT_PATH : "/",
        // DOMAIN_PATH : "localhost",
        
        BASE_URL : "https://osaiten.com/",
        SERVER_PATH : "https://osaiten.com/api/",
        ROOT_PATH : "/",
        DOMAIN_PATH : "osaiten.com",
        
        // BASE_URL : "https://koreshita.tarolab.jp/",
        // SERVER_PATH : "https://koreshita.tarolab.jp/api/",
        // ROOT_PATH : "/",
        // DOMAIN_PATH : "koreshita.tarolab.jp",

        pageName : "",
        reloadValue : "", //画像の更新用,

        isConnection : false,


        isPopup: false,
        popupKbn: "",
        popupTitle: "",
        popupBody: "",
        popupBodyClass : "popupBodyLeft",

        abList : null,
        abIndexList : [
          {kbn : "GROUP_1", url : ""},
          {kbn : "GROUP_1", url : ""},
          {kbn : "GROUP_1", url : ""},
          {kbn : "GROUP_1", url : ""},
        ],

        isMaking : false,
      }
    },
    computed : {
      filterBtnStyle : function () {
        return (kbn, val, key) => {
          let color = "";
          if (key != "99") {
            let array1 = null;
            let array2 = null;
            if (kbn == "MODEL") {
              array1 = this.defFilterModelList;
              array2 = this.defFilterModelCategoryList;
            } else if (kbn == "TYPE") {
              array1 = this.defFilterTypeList;
              array2 = this.defFilterTypeCategoryList;
            } else if (kbn == "ID") {
              array1 = this.defFilterIdList;
              array2 = this.defFilterIdCategoryList;
            }
            let index = -1;
            for (let i = 0; i < array1.length; i++) {
              if (array1[i] == key) {
                index = i;
              }
            }
            if (index == -1) {
              color = "#636e72";
            } else {
              let colorIndex = array2[index];
              if (colorIndex == 99) {
                color = "#636e72";
              } else {
                color = this.categoryColorList[colorIndex];
              }
            }
          } else {
            color = "#636e72";
          }

          if (val == "0") { return "background-color:white; border:1px solid " + color + "; color:" + color;
          } else { return "background-color:" + color + "; color:white"
          }
        }
      },
      postModelLabel : function () {
        return (model) => {
          for (let i = 0; i < this.defFilterModelList.length; i++) {
            if (this.defFilterModelList[i] == model) {
              return this.defFilterModelLabelList[i];
            }
          }
        }
      },
      postTypeLabel : function () {
        return (type) => {
          for (let i = 0; i < this.defFilterTypeList.length; i++) {
            if (this.defFilterTypeList[i] == type) {
              return this.defFilterTypeLabelList[i];
            }
          }
        }
      },
      idTypeLabel : function() {
        return (idType) => {
          for (let i = 0; i < this.defFilterIdList.length; i++) {
            if (this.defFilterIdList[i] == idType) {
              return this.defFilterIdLabelList[i];
            }
          }
          return idType;
        }
      },
    },
    created : function() {
      //広告情報取得
      axios.get(this.$root.SERVER_PATH + 'getAbs')
      .then((response) => {
        // console.log(response.data);
        this.abList = response.data["abs"];
        this.pageAction();
      });
    },
    methods : {
      checkAddress : function (address) {
        const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

        if (!regex.test(address)) {
          //形式間違え
          return 1 
        }

        //ドメインチェック
        let array = address.split('@');
        
        if (array.length != 2) {
          return 1;
        } else {
          let domain = array[1];

          if (domain.indexOf('.jp') == -1 && domain.indexOf('.com') == -1 && domain.indexOf('.net') == -1 && 
            domain.indexOf('.org') == -1 && domain.indexOf('.xyz') == -1) {
            //ドメインエラー
            return 2;
          }
        }

        return 0;
      },
      pushCatBtn : function (name) {
        if (name == "ABOUT") {
          this.$router.push("/about");
        } else if (name == "TWITTER") {
          window.open(this.URL_TWITTER);
        } else if (name == "INSTAGRAM") {
          window.open(this.URL_INSTAGRAM);
        } else if (name == "TERMS") {
          this.$router.push("/terms");
        } else if (name == "PRIVACY") {
          this.$router.push("/privacy");
        }
      },
      pushStart : function () {
        if (this.$route.path == "/") {
          this.scrollPage("start");
        } else {
          this.$router.push("/");
        }
      },
      scrollPage : function(id) {
        document.getElementById(id).scrollIntoView({block:"start"});
      },
      pushFooterBtn : function (name) {
        if (name == "TERMS") {
          this.$router.push("/terms");
        } else if (name == "PRIVACY") {
          this.$router.push("/privacypolicy");
        } else if (name == "TWITTER") {
          window.open("https://twitter.com/taroLab2023");
        } else if (name == "HP") {
          window.open('https://tarolab.jp');
        } else if (name == "STUDYTIMER") {
          window.open('https://studytimer.tarolab.jp');
        }
      },
      pushTweet : function(gameId, gameName) {
        let link = "";
        let text = "一緒にゲームをする友達を見つけよう。無料ゲームフレンド募集掲示板。";
        if (gameId != "") {
          link = "post/" + gameId;
          text = "一緒に" + gameName + "をする友達を見つけよう。無料ゲームフレンド募集掲示板。";
        }
        var url = "https://twitter.com/intent/tweet?" + 
        "hashtags=" + encodeURIComponent('ゲムフレ,フレンド募集') + 
        "&text=" + encodeURIComponent(text) +  
        "&url=" + encodeURIComponent(this.$root.BASE_URL + link);
        window.open(url);
      },
      pushBtn : function (name) {
        if (name == "TERMS") {
          this.$router.push("/terms");
        } else if (name == "PRIVACY") {
          this.$router.push("/privacypolicy");
        } else if (name == "TWITTER") {
          window.open("https://twitter.com/taroLab2023");
        }
      },
      autoScroll : function(kbn) {
        if (kbn == "TOP") {
          document.getElementById("scTop").scrollIntoView({  
            behavior: 'smooth'
          });
        }
      },
      showLoad: function() {
        this.showPopup("LOAD", "", "");
      },
      showPopup: function(kbn, title, body) {
        document.getElementById('body').className = "modalBg";
        this.popupKbn = kbn;
        this.popupTitle = title;
        this.popupBody = body;
        this.isPopup = true;
      },
      pushPopupBg : function () {
        if (this.popupKbn == "TIPS") {
          document.getElementById('body').className = "";
          this.isPopup = false;
        }        
      },
      closePopup : function () {
        document.getElementById('body').className = "";
        this.isPopup = false;
      },
      checkServerResponse : function (response) {
        //サーバー返答時の共通処理
        let resData = response.data;
        if (resData["status"] == this.API_FAILED_AUTH) {
          // console.log("未認証des"); 
          //未認証エラー
          this.isLogin = false;
          this.$router.push("/");
          return false;
        } else if (resData["status"] != this.API_SUCCESS) {
          //エラー
          this.$router.push({path : "/error", query : {errorCode : resData["status"], message : resData["errMsg"]}});
          return false;
        }

        if (resData["isLogin"] == "") {
          //未ログイン
          this.isLogin = false;
        } else {
          this.isLogin = true;
          this.userId = resData["isLogin"];
          this.twitterId = localStorage.twitterId;
          this.displayName = localStorage.displayName
        }
        return true;
      },
      pushTag : function (val) {
			this.$router.push({name: "Top", query : { keyword: val, order: '0'}});
      },
      pushHome : function () {
        if (this.$root.pageName == "Top") {
          location.reload();
        } else {
          this.$router.push("/");
        }
      },
      pageAction : function () {
        if (this.abList == null) {
          return;
        }
        let ranList = [];

        for (let i = 0; i < this.abIndexList.length; i++) {
          let data = this.abIndexList[i];
          let kbn = data["kbn"];

          let targetList = this.abList[kbn];
          let ran = -1;
          while (ran == -1) {
            ran = Math.floor(Math.random() * targetList.length);

            for (let j = 0; j < ranList.length; j++) {
              if (ranList[j] == ran) {
                ran = -1;
                break;
              }
            }
          }
          ranList.push(ran);
          // console.log(ranList);
          data["url"] = targetList[ran];
        }
      }
    }
  }
</script>



<style>
#app {
  font-family: "Zen Maru Gothic","Hiragino Kaku Gothic Pro", Meiryo, "Yu Gothic", "Source Han Sans", "Source Han Sans JP", "Noto Sans JP", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}


</style>
