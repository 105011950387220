<template>
<div>
  <div class="Top2  container-fluid page" style="min-height: 100vh; text-align: left">
    <!-- <div style="height: 200px" /> -->
    <div class="mainArea" style="margin:auto;"> 
    <div class="main">
      <div class="oomidashi">運営者情報</div>

      <div class="sp_line_m" />

      運営者：おサイてん運営事務局
      <div class="sp_line_ss" />
      メールアドレス：info@osaiten.com
      <div class="sp_line_ss" />
      X(旧Twitter)：<span class="cursor fcMain1" v-on:click="pushX">osaiten_x</span>
      <div class="sp_line_ss" />
      Instagram：<span class="cursor fcMain1" v-on:click="pushInstagram">osaiten_official</span>

      <div class="sp_line_l" />

      <div class="oomidashi">お問い合わせ</div>
      <div style="background-color:white">
        <div class="container-fluid">
          <div class="sp_line_m" />
          お名前
          <div class="sp_line_ss" />
          <input type="text" class="inputBase" :maxlength="20" style="width:200px" placeholder="最大20文字まで" v-model="contactName">
          <div class="sp_line_l" />

          ご連絡先メールアドレス
          <div class="sp_line_ss" />
          <input type="text" class="inputBase" :maxlength="100" style="width:50%" placeholder="最大100文字まで" v-model="contactAddress">
          <div class="sp_line_l" />
          
          お問い合わせ内容
          <div class="sp_line_ss" />
          <textarea id="story" class="inputBase" v-model="contactBody" name="story" rows="5" style="height:auto; width:100%" maxlength="1000" placeholder="最大1000文字"></textarea>
          <div class="sp_line_l" />

          <div v-show="errorMessage != ''">
            <span class="colorErr">{{errorMessage}}</span>
            <div class="sp_line_ss" />
          </div>
          <button class="btnBase btnBaseSp" v-on:click="pushContact">送信する</button>
          <div class="sp_line_m" />
        </div>
      </div>

    </div>
    </div>
  </div>
</div>
<div id="overlay" v-show="this.isConfirm">
  <div id="content" style="text-align:center">
    <div class="midashi">お問い合わせ</div>
    <div class="sp_line_s" />
    {{pBody}}
    <div class="sp_line_s" />
    <button type="button" class="btnBase" v-on:click="pushConfirm" style="width:100px; margin-right:5px" >はい</button>
  </div>
</div>
</template>

<script>

import axios from 'axios'
export default {
  name: 'About',
  components: {
  },
  data() {
    return {
      contactName : "",
      contactAddress : "",
      contactBody : "",
      errorMessage : "",

      isConfirm : false,
      pBody : "",
      pKbn : 0,
    }
  },
  created : function () {
  },
  computed : {
  },
  mounted () {
  },
  methods : {
    pushX : function () {
      window.open(this.$root.URL_TWITTER);
    },
    pushInstagram : function () {
      window.open(this.$root.URL_INSTAGRAM);
    },
    pushContact : function () {
      this.errorMessage = "";

      //トリム
      this.contactName = this.contactName.trim();
      this.contactAddress = this.contactAddress.trim();
      this.contactBody = this.contactBody.trim();

      //入力チェック
      if (this.contactName == "") {
        this.errorMessage = "名前を入力してください";
      } else if (this.contactName.length > 20) {
        this.errorMessage = "名前を入力してください";
      } else if (this.contactAddress == "") {
        this.errorMessage = "ご連絡先を入力してください";
      } else if (this.contactAddress.length > 100) {
        this.errorMessage = "ご連絡先を入力してください";
      } else if (this.$root.checkAddress(this.contactAddress) != 0) {
        this.errorMessage = "正しいメールアドレスの形式で入力してください";
      } else if (this.contactBody == "") {
        this.errorMessage = "お問い合わせ内容を入力してください";
      } else if (this.contactBody.length > 1000) {
        this.errorMessage = "お問い合わせ内容を入力してください";
      }
      if (this.errorMessage != "") {
        return;
      }

      this.$root.isConnection = true;

      let params = new URLSearchParams();
      params.append('name', this.contactName);
      params.append('address', this.contactAddress);
      params.append('body', this.contactBody);

      axios.post(this.$root.SERVER_PATH + "sendContact", params)
      .then((response) => {
        let resData = response.data;
        // console.log(resData);
        if (resData["status"] == this.$root.API_SUCCESS) {
          this.pBody = "お問い合わせを受け付けました";
          this.pKbn = 1;
        } else if (resData["status"] == this.$root.API_FAILED_EXEPTION) {
          //メール失敗
          this.pBody = "お問い合わせエラー\nしばらくしてからお問い合わせください";
          this.pKbn = 0;
        }
        this.isConfirm = true;
        this.$root.isConnection = false;
      });
    },
    pushConfirm : function() {
      if (this.pKbn == 1) {
        this.contactName = "";
        this.contactAddress = "";
        this.contactBody = "";
      }
      this.isConfirm = false;
    },
    initAction : function () {

    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.initAction();
    });
  },
  beforeRouteUpdate(to, from, next) {
    next(vm => {
      vm.initAction();
    });
  },
  watch : {
  }
}
</script>

<style>
</style>