import { createApp } from 'vue/dist/vue.esm-bundler';
import App from './App.vue'
import router from './router'
import axios from 'axios'
import './assets/sass/style.scss'
import './assets/sass/style_chk.scss'
import VueGtag from 'vue-gtag-next'

createApp(App)
.use(router, axios)
.use(VueGtag, {
	config: {
		id: 'G-MPRY9SQB0W'
	}
})
.mount('#app')
