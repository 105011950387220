<template>
<div>
  <div class="about container-fluid page" style="">
  <div class="mainArea" style="margin:auto;"> 
  <div class="main">
    <span class="midashi">おサイてんとは？</span>
    <div class="sp_line_ss" />
    登録不要・無料で使える匿名採点サイトです。<br>
    採点してほしい内容と一緒に採点用のURLを投稿して、みんなに採点してもらいましょう。
    <div class="sp_line_l" />

    <span class="midashi">たとえば</span>
    <div class="sp_line_ss" />
    <img :src="this.$root.IMG_PATH + 'saiten_pop1.png'" style="max-width:600px; margin:auto; width:100%">
    <div class="sp_line_l" />
    
    <span class="midashi">どうやって使うの？</span>
    <div class="sp_line_ss" />
    <img :src="this.$root.IMG_PATH + 'saiten_pop2.png'" style="max-width:600px; margin:auto; width:100%">
    <div id="start" class="sp_line_l" />
    
    <div class="oomidashi">さっそく採点シートを作成してみましょう</div>
    <div class="sp_line_m" />

    <div v-show="status == ''">
      <span class="midashi colorMain">Step1 ひとことを設定しましょう</span>
      <div class="sp_line_ss" />
      詳細な説明や採点してほしい点などを入力してください
      <div class="sp_line_s" />
      <div style="width:90%; margin:auto">
        <input type="text" class="inputBase" :maxlength=lenDescription style="width:100%" placeholder="最大100文字まで" v-model="description">
        <div style="text-align:right">（{{description.length}}&nbsp;/&nbsp;100文字）</div>
      </div>
      <div class="sp_line_l" />

      <span class="midashi colorMain">Step2 パスワードを設定しましょう</span>
      <div class="sp_line_ss" />
      採点結果を閲覧する際に必要なパスワードです。
      <div class="sp_line_s" />
      <div style="width:70%; margin:auto">
        <input type="text" class="inputBase" :maxlength=lenPassword style="width:100%;" placeholder="半角英数字最大6桁まで" v-model="password">
        <div class="colorErr font_m" style="text-align:left">
          ※ 他人に知られると採点結果を見られてしまうのでご注意ください<br>
          ※ 忘れてしまうと採点結果を見ることができないのでご注意ください
        </div>
      </div>
      <div class="sp_line_l" />

      <span class="midashi colorMain">Step3 公開設定をしましょう</span>
      <div class="sp_line_ss" />
      「公開」にした場合、訪問者が採点をした後に採点結果を見ることができます<br>
      「非公開」にした場合、採点結果は自分だけしか見ることができません
      <div class="sp_line_s" />
      <button v-show="this.publishing == 0" class="btnBase btnBaseNegative" v-on:click="pushPublic(1)">公開する</button>
      <button v-show="this.publishing == 1" class="btnBase" disabled>公開する</button>
      <button v-show="this.publishing == 1" class="btnBase btnBaseNegative" v-on:click="pushPublic(0)">非公開</button>
      <button v-show="this.publishing == 0" class="btnBase" disabled>非公開</button>
      <div class="sp_line_l" />

      <div id="CHK" class="CHK_TERMS" :class="theme_chk" style="display:flex; justify-content:center">
        <label class="typeA-checkbox">
          <input type="checkbox" v-model="isTerms" true-value="1" false-value="0">
          <div class="indicator borderMain1" ></div>
        </label>
        <span style="margin-top:5px"><span class="pointer fcMain1" @click="pushTerms">利用規約</span>に同意しますか？</span>
      </div>
      <div class="sp_line_s" />

      <div v-show="errorMessage != ''">
        <span class="colorErr">{{errorMessage}}</span>
        <div class="sp_line_ss" />
      </div>
      <button class="btnBase btnBaseSp" v-on:click="pushGenerate">この内容で採点シートを作成する</button>
    </div>
    <div v-show="status == 'GENERATED'">
      <span class="midashi colorMain">以下の内容で採点シートの作成が完了しました</span>
      <div class="sp_line_m" />
      <span class="midashi">ひとこと</span>
      <div class="sp_line_ss" />
      {{description}}
      <div class="sp_line_m" />

      <span class="midashi">パスワード</span>
      <div class="sp_line_ss" />
      {{password}}
      <div class="sp_line_m" />

      <span class="midashi">公開設定</span>
      <div class="sp_line_ss" />
      {{publishingLabel}}
      <div class="sp_line_m" />

      <span class="midashi">採点シートURL</span>
      <div style="width:80%; display:flex; gap:5px; justify-content: center; margin:auto">
        <input type="text" class="inputBase" :maxlength=lenDescription style="width:100%;" placeholder="最大100文字まで" v-model="generatedUrlDisp"><img :src="this.$root.IMG_PATH + 'saiten_reload.png'" style="height:30px" v-on:click="pushReset">
      </div>
      <div class="sp_line_s" />
      <button class="btnBase btnBaseSp" :class="copyBtnClass" v-on:click="copyUrl">{{copyBtnLabel}}</button>
      <div class="sp_line_ss" />
      <span class="colorErr font_m">※ このページを離れるとURLおよびパスワードは確認できませんのでご注意ください</span><br>
      <span class="colorErr font_m">※ 一部のブラウザではコピーできない場合があります</span>
      <div class="sp_line_m" />
      
      <button class="btnBase btnBaseSp" v-on:click="postX">X（旧twitter）で投稿する</button>
      <div class="sp_line_m" />
      
      <img :src="this.$root.IMG_PATH + 'saiten_onegai.png'" style="width:100%; max-width:600px">
    </div>
  </div>
  </div>
  </div>
</div>
</template>

<script>

import axios from 'axios'
import { event } from 'vue-gtag-next'

export default {
  name: 'Saiten',
  components: {
  },
  data() {
    return {
      status : "",
      description : "",
      password : "",
      publishing : 1,

      generatedUrl : "",
      generatedUrlDisp : "",

      lenDescription : 100,
      lenPassword : 6,

      errorMessage : "",

      copyBtnLabel : "採点シートのURLをコピー",
      copyBtnClass : "",

      isTerms : false,
    }
  },
  created : function () {
  },
  computed : {
    publishingLabel : function () {
      if (this.publishing == 0) {
        return "公開しない";
      } else {
        return "公開する";
      }
    },
  },
  mounted () {
  },
  methods : {
    pushPublic : function (val) {
      this.publishing = val;
    },
    pushGenerate : function() {
      //入力チェック処理
      this.errorMessage = "";
      if (this.isTerms == false) {
        this.errorMessage = "利用規約に同意してください";
      } else if (this.description.length > this.lenDescription) {
        this.errorMessage = "ひとことは100文字以内で入力してください";
      } else if (this.password == "") {
        this.errorMessage = "パスワードを入力してください";
      } else if (this.password.length > this.lenPassword) {
        this.errorMessage = "パスワードは6文字以内で入力してください";
      } else {
        let re = /^[a-zA-Z0-9]+$/;
        if(!re.test(this.password)) {
          this.errorMessage = "パスワードは半角英数字で入力してください";
        }
      }
      
      if (this.errorMessage != "") {
        return;
      }
      event('clicked hoge btn');
      this.$root.isConnection = true;

      let params = new URLSearchParams();
      params.append('description', this.description);
      params.append('publishing', this.publishing);
      params.append('password', this.password);

      axios.post(this.$root.SERVER_PATH + "generateSaiten", params)
      .then((response) => {
        let resData = response.data;
        // console.log(resData);
        if (resData["status"] == this.$root.API_SUCCESS) {
          this.status = "GENERATED";
          this.generatedUrl = this.$root.BASE_URL + resData["saitenId"];
          this.generatedUrlDisp = this.generatedUrl;
          this.$root.isConnection = false;
        }
      });
    },
    pushReset : function() {
      this.generatedUrlDisp = this.generatedUrl;
    },
    postX : function () {
      let text = "採点おねがいします！\n";
      var url = "https://twitter.com/intent/tweet?" + 
      "hashtags=" + encodeURIComponent('おサイてん') + 
      "&text=" + encodeURIComponent(text) + 
      "&url=" + encodeURIComponent(this.generatedUrl);
      window.open(url);
    },
    copyUrl : function() {
      if (!navigator.clipboard) {
        console.log("残念。このブラウザは対応していません...");
        this.copyBtnLabel = "コピーに失敗しました";
        this.copyBtnClass = "";
        setTimeout(() => {
          this.copyBtnLabel = "採点シートのURLをコピー";
          this.copyBtnClass = "";
        }, 1000);
        return;
      }

      navigator.clipboard.writeText(this.generatedUrl).then(
        () => {
          console.log('コピー成功👍');
          this.copyBtnLabel = "コピーしました";
          this.copyBtnClass = "";
          setTimeout(() => {
            this.copyBtnLabel = "採点シートのURLをコピー";
            this.copyBtnClass = "";
          }, 1000);
        },
        () => {
          console.log('コピー失敗😭');
          this.copyBtnLabel = "コピーに失敗しました";
          this.copyBtnClass = "btnBaseError";
          setTimeout(() => {
            this.copyBtnLabel = "採点シートのURLをコピー";
            this.copyBtnClass = "";
          }, 1000);
        }
      );
    },
    pushTerms : function () {
      window.open(this.$root.BASE_URL + 'terms');
    },
    initAction : function () {

    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.initAction();
    });
  },
  beforeRouteUpdate(to, from, next) {
    next(vm => {
      vm.initAction();
    });
  },
  watch : {
  }
}
</script>

<style>
</style>