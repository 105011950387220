// import Top from '../views/Top.vue'
import NotFound from '../views/NotFound.vue'
// import Chat from '../views/Chat.vue'
// import Test from '../views/Test.vue'
// import Gemi from '../views/Gemi.vue'
import Saiten from '../views/Saiten.vue'
import Saiten2 from '../views/Saiten2.vue'
import About from '../views/About.vue'
import Terms from '../views/Terms.vue'
import Privacy from '../views/Privacy.vue'

const routes = [
  {
    path: '/',
    name: 'Saiten',
    component: Saiten
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/:id',
    name: 'Saiten2',
    component: Saiten2
  },
  {
    //無効なURL
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
  // {
  //   path: '/gemi',
  //   name: 'Gemi',
  //   component: Gemi
  // },
  // {
  //   path: '/chat',
  //   name: 'Chat',
  //   component: Chat
  // },
  // {
  //   path: '/test',
  //   name: 'Test',
  //   component: Test
  // },
  // {
  //   path: '/a',
  //   name: 'Top',
  //   component: Top
  // },
  // {
  //   //無効なURL
  //   path: "/:pathMatch(.*)*",
  //   name: "NotFound",
  //   component: NotFound,
  // },
]


// import { VueRouter } from 'vue-router'
// const router = new VueRouter({
//   mode : "history",
//   hash : false,
//   // base:'/myquiz/public/',
//   routes
// })

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { top: 0, behavior:'instant' }
    // return { x: 0, y: 0 }
  }
};

import { createRouter, createWebHistory } from 'vue-router'
const router = createRouter({
  // history: createWebHistory(),
  history: createWebHistory('/'),
  routes,
  // scrollBehavior
})

// import { createRouter, createWebHashHistory } from 'vue-router'
// const router = createRouter({
//   history : createWebHashHistory(),
//   routes
// })

export default router
