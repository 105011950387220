import "core-js/modules/es.array.push.js";
// import axios from 'axios'
export default {
  name: 'NotFound',
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      console.log("NOT FOUND");
      vm.$router.push("/");
    });
  },
  beforeRouteUpdate(to, from, next) {
    next(vm => {
      console.log("NOT FOUND");
      vm.$router.push("/");
    });
  }
};