<template>
<div>
  <!-- 
    デザイン調整
    カード
    shareボタン
    採点者の情報？（性別、年代、コメントなど）
    閲覧側の詳細
   -->
  <div class="about container-fluid page" style="">
  <div class="mainArea" style="margin:auto;"> 
  <div class="main">
    
    <div v-show="openPasswordFlg == false">
      <div v-show="errorMessage2 != ''" style="text-align:right">
        <span class="colorErr">{{errorMessage2}}</span>
        <div class="sp_line_ss" />
      </div>
      <div style="display:flex; justify-content:right">
        <input type="text" class="inputBase" :maxlength=lenDescription style="width:100px" placeholder="パスワード" v-model="inputPassword">
        <button class="btnBase btnBaseSp" style="padding:0" v-on:click="pushPassword">採点結果を見る</button>
      </div>
    </div>

    <div class="sp_line_ss" />
    <div v-show="status == ''">
      <div class="oomidashi bgTest" style="font-size:40px">サイてんページ</div>
      <div v-show="description != ''">
        <div class="sp_line_s" />
        <div class="hukidashi">{{description}}</div>
        <div class="sp_line_s" />
      </div>
      <div class="sp_line_s" />
      <span class="midashi">点数を入力してください</span>
      <div class="sp_line_ss" />
      <div style="margin-top:-50px">
        <span style="font-size:150px; margin-left:70px">{{inputScore}}</span><span style="font-size:70px">点</span>
      </div>
      <div class="sp_line_s" />

      <div style="display:flex; gap:20px; flex-wrap:wrap; justify-content:center">
        <button class="btnBase numBtn" v-on:click="pushScore(98)">C</button>
        <button class="btnBase numBtn" v-on:click="pushScore(0)">0</button>
        <button class="btnBase numBtn" v-on:click="pushScore(1)">1</button>
        <button class="btnBase numBtn" v-on:click="pushScore(2)">2</button>
        <button class="btnBase numBtn" v-on:click="pushScore(3)">3</button>
        <button class="btnBase numBtn" v-on:click="pushScore(4)">4</button>
        <button class="btnBase numBtn" v-on:click="pushScore(5)">5</button>
        <button class="btnBase numBtn" v-on:click="pushScore(6)">6</button>
        <button class="btnBase numBtn" v-on:click="pushScore(7)">7</button>
        <button class="btnBase numBtn" v-on:click="pushScore(8)">8</button>
        <button class="btnBase numBtn" v-on:click="pushScore(9)">9</button>
      </div>
      <div class="sp_line_l" />
      <div class="sp_line_m" />
      
      <div class="oomidashi">オプション設定</div>
      各種オプションは任意です
      <div class="sp_line_m" />

      <span class="midashi">性別</span>
      <div class="sp_line_s" />
      <div style="display:flex; gap:20px; flex-wrap:wrap; justify-content:center">
        <button class="btnBase" :class="option1Class(0)" v-on:click="pushOption1(0)">無回答</button>
        <button class="btnBase" :class="option1Class(1)" v-on:click="pushOption1(1)">男</button>
        <button class="btnBase" :class="option1Class(2)" v-on:click="pushOption1(2)">女</button>
        <button class="btnBase" :class="option1Class(3)" v-on:click="pushOption1(3)">どちらでもない</button>
      </div>
      <div class="sp_line_l" />

      <span class="midashi">年代</span>
      <div class="sp_line_s" />
      <div style="display:flex; gap:20px; flex-wrap:wrap; justify-content:center">
        <button class="btnBase" :class="option2Class(0)" v-on:click="pushOption2(0)">無回答</button>
        <button class="btnBase" :class="option2Class(1)" v-on:click="pushOption2(1)">10代</button>
        <button class="btnBase" :class="option2Class(2)" v-on:click="pushOption2(2)">20代</button>
        <button class="btnBase" :class="option2Class(3)" v-on:click="pushOption2(3)">30代</button>
        <button class="btnBase" :class="option2Class(4)" v-on:click="pushOption2(4)">40代</button>
        <button class="btnBase" :class="option2Class(5)" v-on:click="pushOption2(5)">50代</button>
        <button class="btnBase" :class="option2Class(6)" v-on:click="pushOption2(6)">60代以上</button>
      </div>
      <div class="sp_line_l" />

      <div v-show="errorMessage1 != ''">
        <span class="colorErr">{{errorMessage1}}</span>
        <div class="sp_line_ss" />
      </div>
      <button class="btnBase btnBaseSp" style="width:300px" v-on:click="pushScore(99)">採点結果を送信する</button>
    </div>
    <div v-show="status == 'THANKS'">
      <div class="oomidashi" style="font-size:35px">採点が完了しました</div>
      <div class="sp_line_l" />
      ご協力ありがとうございました
    </div>  
    <div v-show="status == 'RESULT'">
      <div class="oomidashi" style="font-size:40px">サイてん結果</div>
      <div class="sp_line_m" />
      <div v-show="this.resScore == -1">
        <span class="midashi">まだ採点されていません</span>
      </div>
      <div v-show="this.resScore != -1">
        <span class="midashi">みんなのサイてん結果は</span>
        <div style="margin-top:-50px">
          <span style="font-size:150px; margin-left:30px">{{disp("all", "score")}}</span><span style="font-size:70px">点</span>
          <div v-show="isDispCount('all') && dispCountFlg" style="margin-top:-30px; margin-bottom:40px">
            <span style="font-size:30px;">({{disp('all', 'count')}}人)</span>
          </div>
        </div>

        <span v-show="isDisp('123')">
          <div class="oomidashi">性別ごと</div>
          <div class="sp_line_m" />
          <div style="display:flex; flex-wrap:wrap; gap:8%; justify-content:center">
            <div v-show="isDisp('1')">
              <img :src="this.$root.IMG_PATH + 'saiten_sei1.png'" style="width:60px">
              <div style="margin-top:-20px">
                <span style="font-size:60px">{{disp("1", "score")}}</span><span style="font-size:25px">点</span>
              </div>
              <div v-show="isDispCount('1') && dispCountFlg" style="margin-top:-10px">
                <span style="font-size:20px;">({{disp('1', 'count')}}人)</span>
              </div>
            </div>
            <div v-show="isDisp('2')">
              <img :src="this.$root.IMG_PATH + 'saiten_sei2.png'" style="width:60px">
              <div style="margin-top:-20px">
                <span style="font-size:60px">{{disp("2", "score")}}</span><span style="font-size:25px">点</span>
              </div>
              <div v-show="isDispCount('2') && dispCountFlg" style="margin-top:-10px">
                <span style="font-size:20px;">({{disp('2', 'count')}}人)</span>
              </div>
            </div>
            <div v-show="isDisp('3')">
              <img :src="this.$root.IMG_PATH + 'saiten_sei3.png'" style="width:60px">
              <div style="margin-top:-20px">
                <span style="font-size:60px">{{disp("3", "score")}}</span><span style="font-size:25px">点</span>
              </div>
              <div v-show="isDispCount('3') && dispCountFlg" style="margin-top:-10px">
                <span style="font-size:20px;">({{disp('3', 'count')}}人)</span>
              </div>
            </div>
          </div>
          <div class="sp_line_l" />
        </span>

        <span v-show="isDisp('456')">
          <div class="oomidashi">年代ごと</div>
          <div class="sp_line_m" />
          <div v-for="(val, index) in option2Array" :key="index">
            <span v-show="isDisp('all-' + val)">
              <span class="midashi">{{val}}0代{{option2Label(val)}}</span>
              <div class="sp_line_ss" />
              <div style="margin-top:-20px">
                <span style="font-size:60px">{{disp("all-" + val, "score")}}</span><span style="font-size:25px">点</span>
                <div v-show="isDispCount('all-' + val) && dispCountFlg" style="margin-top:-10px">
                  <span style="font-size:20px;">({{disp('all-' + val, 'count')}}人)</span>
                </div>
              </div>
              <br>
              <div style="display:flex; flex-wrap:wrap; gap:15px; justify-content:center; margin-top:-10px">
                <span v-show="isDisp(val + '-1')">
                  <img :src="this.$root.IMG_PATH + 'saiten_sei1.png'" style="width:30px; margin-top:-10px">
                  <span style="font-size:30px">{{disp(val + "-1", "score")}}</span>
                  <span style="font-size:15px">点</span>
                  <div v-show="isDispCount(val + '-1') && dispCountFlg" style="margin-top:0px">
                    <span style="font-size:15px;">({{disp(val + '-1', 'count')}}人)</span>
                  </div>
                </span>
                <span v-show="isDisp(val + '-2')">
                  <img :src="this.$root.IMG_PATH + 'saiten_sei2.png'" style="width:30px; margin-top:-10px">
                  <span style="font-size:30px">{{disp(val + "-2", "score")}}</span>
                  <span style="font-size:15px">点</span>
                  <div v-show="isDispCount(val + '-2') && dispCountFlg" style="margin-top:0px">
                    <span style="font-size:15px;">({{disp(val + '-2', 'count')}}人)</span>
                  </div>
                </span>
                <span v-show="isDisp(val + '-3')">
                  <img :src="this.$root.IMG_PATH + 'saiten_sei3.png'" style="width:30px; margin-top:-10px">
                  <span style="font-size:30px">{{disp(val + "-3", "score")}}</span>
                  <span style="font-size:15px">点</span>
                  <div v-show="isDispCount(val + '-3') && dispCountFlg" style="margin-top:0px">
                    <span style="font-size:15px;">({{disp(val + '-3', 'count')}}人)</span>
                  </div>
                </span>
              </div>
              <div class="sp_line_l" />
            </span>
          </div>
        </span>
        
        <div v-show="openPasswordFlg">
          スクリーンショットなどを撮る場合に
          <div class="sp_line_s" />
          <button class="btnBase btnBaseSp" v-on:click="this.dispCountFlg = !this.dispCountFlg">{{dispCountBtnLabel}}</button>
          <div class="sp_line_m" />
        </div>
      </div>

    </div>
    <div v-show="(status == 'THANKS' || status == 'RESULT') && !openPasswordFlg">
      <div class="sp_line_l" />
      <span class="midashi">あなたも採点してもらいませんか？</span>
      <div class="sp_line_ss" />
      URLを一緒に投稿するだけ！登録不要・無料で簡単！
      <div class="sp_line_s" />
      <button class="btnBase btnBaseSp" v-on:click="this.$router.push('/')">採点してもらう</button>

      <div class="sp_line_l" />

      <div style="text-align:right">
        <button class="btnBase" v-on:click="pushRetry">採点を修正する</button>
      </div>
      <div class="sp_line_l" />
    </div>
  </div>
  </div>
  </div>
</div>
<div id="overlay" v-show="this.isConfirm">
  <div id="content" style="text-align:center">
    <div class="midashi">確認</div>
    <div class="sp_line_s" />
    {{inputScore}}点で送信してよろしいですか？
    <div class="sp_line_s" />
    <button type="button" class="btnBase" v-on:click="pushConfirm(1)" style="width:100px; margin-right:5px" >はい</button>
    <button type="button" class="btnBase btnBaseError" v-on:click="pushConfirm(0)" style="width:100px; margin-left:5px" >キャンセル</button>
  </div>
</div>
</template>

<script>

import axios from 'axios'
export default {
  name: 'Saiten2',
  components: {
  },
  data() {
    return {
      status : "",

      inputScore : 0,
      inputPassword : "",
      inputOption1 : 0,
      inputOption2 : 0,

      saitenId : "",
      userId : "",
      description : "",

      resScore : 0,
      resCount : 0,

      lenPassword : 6,

      errorMessage1 : "",
      errorMessage2 : "",
      
      isConfirm : false,

      scoreList : {},

      option2Array : ["1","2","3","4","5","6"],

      openPasswordFlg : false,
      dispCountFlg : true,
    }
  },
  created : function () {
  },
  computed : {
    dispCountBtnLabel : function () {
      if (this.dispCountFlg) {
        return "人数を非表示";
      } else {
        return "人数を表示";
      }
    },
    disp : function () {
      return (val, kbn) => {
        if (this.scoreList[val] != null) {
          return this.scoreList[val][kbn];
        } else {
          return "";
        }
      }
    },
    isDispCount : function () {
      return (val) => {
        if (this.scoreList[val] != null) {
          if (this.scoreList[val]["count"] != null) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    isDisp : function () {
      return (val) => {
        if (this.scoreList.length == 0) {
          return false;
        }

        if (val == "123") {
          //性別ごと
          if (this.scoreList["1"] != null || this.scoreList["2"] != null || this.scoreList["3"] != null) {
            return true;
          } else {
            return false;
          }
        }
        if (val == "456") {
          //年代ごと
          if (this.scoreList["all-1"] != null || this.scoreList["all-2"] != null || this.scoreList["all-3"] != null || this.scoreList["all-4"] != null || this.scoreList["all-5"] != null || this.scoreList["all-6"] != null) {
            return true;
          } else {
            return false;
          }
        }

        if (val == "all-1" || val == "all-2" || val == "all-3" || val == "all-4" || val == "all-5" || val == "all-6") {
          if (val in this.scoreList) {
            return true;
          } else {
            return false;
          } 
        } else {
          if (this.scoreList[val] != null) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
    option1Class : function () {
      return (val) => {
        if (val == this.inputOption1) {
          return "";
        } else {
          return "btnBaseNegative";
        }
      }
    },
    option2Class : function () {
      return (val) => {
        if (val == this.inputOption2) {
          return "";
        } else {
          return "btnBaseNegative";
        }
      }
    },
    option2Label : function () {
      return (val) => {
        if (val == "6") {
          return "以上";
        } else {
          return "";
        }
      }
    }
  },
  mounted () {
  },
  methods : {
    pushOption1 : function (val) {
      this.inputOption1 = val;
    },
    pushOption2 : function (val) {
      this.inputOption2 = val;
    },
    pushPassword : function () {
      //入力チェック
      this.errorMessage2 = "";
      if (this.inputPassword == "") {
        this.errorMessage2 = "パスワードを入力してください";
      } else if (this.inputPassword.length > this.lenPassword) {
        this.errorMessage2 = "パスワードは6文字以内で入力してください";
      } else {
        let re = /^[a-zA-Z0-9]+$/;
        if(!re.test(this.inputPassword)) {
          this.errorMessage2 = "パスワードは半角英数字で入力してください";
        }
      }
      
      if (this.errorMessage2 != "") {
        return;
      }
      this.$root.isConnection = true;

      let params = new URLSearchParams();
      params.append('saitenId', this.saitenId);
      params.append('password', this.inputPassword);
      axios.post(this.$root.SERVER_PATH + "openScore", params)
      .then((response) => {
        let resData = response.data;
        // console.log(resData);
        let scoreData = resData["resultData"];
        this.scoreList = {};
        if (scoreData == null) {
          this.resScore = -1;
        } else {
          this.resScore = parseFloat(scoreData["sum"] / parseFloat(scoreData["count"]));

          for (const key in scoreData) {
            // this.scoreList[key] = scoreData[key]["score"];
            if ('count' in scoreData[key]) {
              this.scoreList[key] = {"score" : scoreData[key]["score"], "count" : scoreData[key]["count"]}
            } else {
              this.scoreList[key] = {"score" : scoreData[key]["score"]};
            }
          }
        }
        console.log(this.resScore);
        this.status = "RESULT";
        this.$root.isConnection = false;
        this.openPasswordFlg = true;
      });
    },
    pushRetry : function() {
      this.status = "";
      this.$root.scrollPage("top");
    },
    pushScore : function(val) {
      if (val == "99") {
        //入力チェック
        this.errorMessage1 = "";
        if (this.inputScore < 0 || this.inputScore > 100) {
          this.errorMessage1 = "スコアは0点以上、100点未満で入力してください";
        } else if (this.inputOption1 < 0 || this.inputOption1 > 3) {
          this.errorMessage1 = "性別の選択が間違っています";
        } else if (this.inputOption2 < 0 || this.inputOption2 > 6) {
          this.errorMessage1 = "年代の選択が間違っています";
        }
        
        if (this.errorMessage1 != "") {
          return;
        }

        this.isConfirm = true;
      } else if (val == "98") {
        this.inputScore = 0;
      } else {
        let add = parseInt(val);
        let kari = this.inputScore * 10 + add;
        if (kari > 100) {
          kari = add;
        }
        this.inputScore = kari;
      }
    },
    pushConfirm : function(val) {
      if (val == 0) {
        this.isConfirm = false;
      } else {
        //送信
        this.isConfirm = false;
        this.$root.isConnection = true;

        let params = new URLSearchParams();
        params.append('saitenId', this.saitenId);
        params.append('userId', this.userId);
        params.append('score', this.inputScore);
        params.append('option1', this.inputOption1);
        params.append('option2', this.inputOption2);
        axios.post(this.$root.SERVER_PATH + "sendScore", params)
        .then((response) => {
          let resData = response.data;
          // console.log(resData);
          
          //公開データの場合
          if (resData["publishing"] == "0") {
            this.status = "THANKS";
          } else if (resData["publishing"] == "1") {
            let scoreData = resData["resultData"];
            this.scoreList = {};
            if (scoreData == null) {
              this.resScore = -1;
            } else {
              this.resScore = parseFloat(scoreData["sum"] / parseFloat(scoreData["count"]));

              for (const key in scoreData) {
                // this.scoreList[key] = scoreData[key]["score"];
                if ('count' in scoreData[key]) {
                  this.scoreList[key] = {"score" : scoreData[key]["score"], "count" : scoreData[key]["count"]}
                } else {
                  this.scoreList[key] = {"score" : scoreData[key]["score"]};
                }
              }
            }
            this.status = "RESULT";

          }
          localStorage.setItem("SAITEN_OP1", this.inputOption1);
          localStorage.setItem("SAITEN_OP2", this.inputOption2);
          this.$root.scrollPage("top");
          this.$root.isConnection = false;
        });
      }
    },
    initAction : function () {
      this.saitenId = this.$route.params.id;
      if (this.saitenId == null || this.saitenId == "" || this.saitenId.length != 7) {
        this.$router.push("/");
        return;
      }

      this.$root.isConnection = true;

      this.userId = localStorage.getItem("SAITEN_USER_ID") || "";
      this.inputOption1 = localStorage.getItem("SAITEN_OP1") || 0;
      this.inputOption2 = localStorage.getItem("SAITEN_OP2") || 0;

      // console.log(this.userId);
      let params = new URLSearchParams();
      params.append('saitenId', this.saitenId);
      params.append('userId', this.userId);
      axios.post(this.$root.SERVER_PATH + "getSaitenInfo", params)
      .then((response) => {
        let resData = response.data;
        // console.log(resData);
        if (resData["status"] == this.$root.API_SUCCESS) {
          this.description = resData["description"];

          let newUserId = resData["userId"];
          if (this.userId != newUserId) {
            localStorage.setItem("SAITEN_USER_ID", newUserId);
            this.userId = newUserId;
          }

          if (resData["retKbn"] == "PUBLIC") {
            //採点済みで公開
            let scoreData = resData["resultData"];
            this.scoreList = {};
            if (scoreData == null) {
              this.resScore = -1;
            } else {
              this.resScore = parseFloat(scoreData["sum"] / parseFloat(scoreData["count"]));
              
              for (const key in scoreData) {
                if ('count' in scoreData[key]) {
                  this.scoreList[key] = {"score" : scoreData[key]["score"], "count" : scoreData[key]["count"]}
                } else {
                  this.scoreList[key] = {"score" : scoreData[key]["score"]};
                }
              }
              this.status = "RESULT";
            }
          } else if (resData["retKbn"] == "PRIVATE") {
            //採点済みで非公開
            this.status = "THANKS";
          }
        }
        this.$root.isConnection = false;
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.initAction();
    });
  },
  beforeRouteUpdate(to, from, next) {
    next(vm => {
      vm.initAction();
    });
  },
  watch : {
  }
}
</script>

<style>
</style>